//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PublicHeader from "./Header";
import PublicFooter from "./Footer";
import { required, email, FormMixin, default as UiForm } from "@/components/Form/Form";
import Focus from "@/components/Focus";
export default {
  mixins: [FormMixin],
  meta: {
    /*@V:
            title: '联系方式'
            */
    ////!&: 2022-06-24 title: "SalesFinder - 联系我们",
    title: "联系我们",
    meta: [{
      name: "description",
      content: "我们总是很高兴收到您有关 SalesFinder 服务的问题, 反馈和建议."
    }, {
      name: "viewport",
      content: "width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0"
    }]
  },
  components: {
    PublicHeader,
    UiForm,
    Focus,
    PublicFooter
  },
  data() {
    return {
      active_inputs: [{
        name: "name",
        title: "商店名称",
        placeholder: "例如 明涛",
        type: "text",
        error_text: "",
        show_error: false,
        show_success: false,
        model: "",
        active: false,
        touched: false,
        validators: [required()],
        validateOnBlur: false
      }, {
        name: "email_address",
        title: "Email",
        placeholder: "例如 24120910@qq.com",
        type: "email",
        error_text: "",
        show_error: false,
        show_success: false,
        model: "",
        active: false,
        touched: false,
        validators: [required(), email()],
        validateOnBlur: false
      }, {
        name: "phone_number",
        title: "电话(可选)",
        placeholder: "例如 +86 186 1111 1111",
        type: "text",
        error_text: "",
        show_error: false,
        show_success: false,
        model: "",
        active: false,
        touched: false,
        validateOnBlur: false
      }, {
        name: "message",
        title: "一条评论",
        placeholder: "我们该怎样帮助你?",
        type: "textarea",
        error_text: "",
        show_error: false,
        show_success: false,
        model: "",
        active: false,
        touched: false,
        validators: [required()],
        validateOnBlur: false
      }],
      sentFeedback: null
    };
  },
  methods: {
    async onSubmit() {
      const isValid = await this.validate(true);
      if (!isValid) {
        return;
      }
      const feedback = await this.$store.dispatch("feedback/send", {
        name: this.active_inputs.find(i => i.name === "name").model.trim(),
        email_address: this.active_inputs.find(i => i.name === "email_address").model.trim().toLowerCase(),
        phone_number: this.active_inputs.find(i => i.name === "phone_number").model.trim(),
        message: this.active_inputs.find(i => i.name === "message").model.trim()
      });
      this.sentFeedback = feedback;
    }
  }
};